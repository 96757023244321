<style lang="scss" scoped>
.el-steps {
  margin-bottom: 20px;
}
</style>
<template>
  <div class="height_100">
    <!-- 步驟條 -->
    <el-steps
      :active="state.stepActive"
      align-center
      class="width_100"
      finish-status="success"
    >
      <el-step
        :title="item"
        v-for="(item, index) in state.stepList"
        :key="index"
      ></el-step>
    </el-steps>
    <!-- 主体内容 -->
    <el-tabs
      tab-position="left"
      :before-leave="tabClick"
      v-show="!state.isSuccess"
      v-model="state.tabsActive"
    >
      <el-tab-pane label="基本信息" name="0">
        <pro-info ref="ProInfoRef"></pro-info>
      </el-tab-pane>
      <el-tab-pane label="商品規格" name="1" v-if="state.spec">
        <pro-spec :specData="state.specData" ref="SpecRef"></pro-spec>
      </el-tab-pane>
      <el-tab-pane label="商品圖片" name="2">
        <pro-img ref="ImgRef"></pro-img>
      </el-tab-pane>
      <el-tab-pane label="商品内容" name="3">
        <pro-content ref="ContentRef" @sendContent="sendContent"></pro-content>
      </el-tab-pane>
    </el-tabs>
    <!-- 完成添加頁面 -->
    <pro-success v-show="state.isSuccess" @addPro="addPro"></pro-success>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  defineComponent,
  getCurrentInstance,
  watch,
  onMounted,
} from "vue";
import ProInfo from "./components/pro-info";
import ProSpec from "./components/pro-spec";
import ProImg from "./components/pro-img";
import ProContent from "./components/pro-content";
import ProSuccess from "./components/pro-success";

export default defineComponent({
  components: {
    ProInfo,
    ProSpec,
    ProImg,
    ProContent,
    ProSuccess,
  },
  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http, $utils } = proxy;
    const ProInfoRef = ref();
    const SpecRef = ref();
    const ImgRef = ref();
    const ContentRef = ref();
    const state = reactive({
      stepList: ["基本信息", "商品規格", "商品圖片", "商品内容", "完成"],
      stepActive: 0, // 當前步驟
      tabsActive: "0", // 當前步驟
      spec: true, // 有无规格
      data: {
        // 添加商品数据
      },
      specData: {}, // 規格參數
      select_spec: [], // 選中的id
      isSuccess: false, // 是否添加完成
    });

    watch(
      () => state.isSuccess,
      (val) => {
        if (val) {
          state.stepActive = 4;
        }
      }
    );

    onMounted(() => {
      watch(
        () => ProInfoRef.value.valueRef.ruleForm.is_point,
        (val, old) => {
          state.spec = val;
          state.data = Object.assign({ spec: state.spec }, state.data);
          state.stepList = val
            ? ["基本信息", "商品規格", "商品圖片", "商品内容", "完成"]
            : ["基本信息", "商品圖片", "商品内容", "完成"];
        }
      );
      watch(
        () => ImgRef.value.state.imgList,
        (val) => {
          state.data = Object.assign({ imgs: val }, state.data);
        },
        { deep: true }
      );
    });

    const tabClick = (activeName, oldActiveName) => {
      let fl = true;
      if (oldActiveName == 0) {
        // 商品信息
        ProInfoRef.value.$refs.ruleForm.validate((res) => {
          fl = res;
          if (!fl) $message.error("商品信息有誤");
          state.data = Object.assign(
            ProInfoRef.value.valueRef.ruleForm,
            state.data
          );
        });
      } else if (oldActiveName == 1) {
        if (!state.spec) return;
        // 商品規格
        state.select_spec = [];
        SpecRef.value.state.specData.forEach((item) => {
          const arr = item.goods_attr_combine.filter((curr) => curr.checked);
          const index = state.select_spec.findIndex(
            (curr) => curr.id == item.id
          );
          if (arr.length) {
            const obj = {
              id: item.id,
              arr,
            };
            index !== -1
              ? (state.select_spec[index].arr = arr)
              : state.select_spec.push(obj);
          }
        });
      } else if (oldActiveName == 2) {
        // 商品圖片
      } else if (oldActiveName == 3) {
        // 商品内容
      }

      if (fl) {
        if (state.spec) {
          state.stepActive = Number(activeName);
          if (!state.select_spec.length) {
            $http
              .getGoodsAttr({
                limit: 10,
                page: 1,
                goods_sort_id:
                  state.data.classify[state.data.classify.length - 1],
              })
              .then((res) => {
                state.specData = res.data;
              });
          }
        } else {
          activeName == 2
            ? (state.stepActive = 1)
            : activeName == 3
            ? (state.stepActive = 2)
            : "";
        }
      }

      return fl;
    };

    const sendContent = async (data) => {
      // 添加商品
      const select_spec = [];
      if (state.spec) {
        if (!state.select_spec.length)
          return $message.error("必需選擇至少一個商品規格");
      }
      state.select_spec.forEach((item) => {
        item.arr.forEach((curr) => {
          const obj = {
            attr_values_id: curr.attr_values_id,
            id: curr.id,
            attr_values_price: curr.pic,
            attr_key_id: item.id,
          };
          select_spec.push(obj);
        });
      });

      const result = {
        admin_id: $utils.getCookie("adminId"),
        goods_name: state.data.goods_name,
        goods_img: state.data.goods_img,
        describe: state.data.describe,
        is_hot: state.data.is_hot,
        feedback_integral: state.data.feedback_integral,
        keysword: state.data.keysword,
        has_specs: Number(state.spec),
        sort_id: state.data.classify[state.data.classify.length - 1],
        goods_introduce: data,
        price: state.data.price,
        cost: state.data.cost,
        supplier: state.data.datum,
        goods_stock: state.data.inventory,
        status: state.data.status,
        goods_specs: select_spec,
        imgs: state.data.imgs ? state.data.imgs : [],
      };
      const res = await $http.addGoods(result);
      if (res.status !== 200) return;
      $message.success("添加成功");
      state.stepActive = state.spec ? 4 : 3;
      state.isSuccess = true;
    };

    const addPro = () => {
      // window.location.reload();
      // proxy.$router.back();
      // proxy.$router.go("/GoodsManagement");
      ProInfoRef.value.reset();
      ContentRef.value.reset();
      ImgRef.value.reset();
      state.isSuccess = false;
      state.stepActive = 0;
      state.tabsActive = "0";
    };

    return {
      state,
      tabClick,
      ProInfoRef,
      SpecRef,
      ImgRef,
      ContentRef,
      sendContent,
      addPro,
    };
  },
});
</script>
