<style lang="scss" scoped>
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    background-color: yellowgreen;
    margin-top: 200px;
    color: white;
    font-size: 60px;
    margin-bottom: 10px;
  }
}
</style>
<template>
  <div class="success">
    <div>
      <i class="el-icon-check"></i>
    </div>
    <el-button type="primary" size="mini" @click="addPro">繼續添加</el-button>
  </div>
</template>

<script>
export default {
  emits: {
    addPro(data) {
      return data;
    },
  },
  setup(prop, { emit }) {
    const addPro = () => {
      // emit("addPro", true);
      window.location.reload();
    };
    return { addPro };
  },
};
</script>
